import { Injectable } from '@angular/core';

import { ClientConfig, ClientConfigProductName, ClientConfigService } from '@frontend/vanilla/core';

@ClientConfig({ key: 'mvsNavigation', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: mvsNavigationFactory,
})
export class MvsNavigationClientConfig {
    navigationBar: any;
    connectionConfig: any;
}

@ClientConfig({ key: 'mvsPage', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: mvsPageFactory,
})
export class MvsPageClientConfig {
    messages: any;
    prefix: string;
    sportsPage: string;
    sportsTitle: string;
    cashierWithdrawUrlTemplate: string;
    cashierDepositUrlTemplate: string;
    virtualsportsPage: string;
    virtualsportsTitle: string;
}

@ClientConfig({ key: 'mvsGames', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: mvsGamesFactory,
})
export class MvsGamesClientConfig {
    games: any;
}

@ClientConfig({ key: 'mvsFeatures', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: mvsFeaturesFactory,
})
export class MvsFeaturesClientConfig {
    showGameTitle: boolean;
    enableMGSGameLaunch: boolean;
    enableBetSearchApi: boolean;
    enableSEOBox: boolean;
    enableAllSportsInNavigation: boolean;
    extendedTopMenuStatus: boolean;
    extendedTopMenuToRight: boolean;
}

@ClientConfig({ key: 'mvsMetaTags', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: mvsMetaTagsFactory,
})
export class MvsMetaTagsClientConfig {
    pages: any;
}

@ClientConfig({ key: 'mvsOptimizedTeaser', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: TeaserConfigFactory,
})
export class TeasersClientConfig {
    teaserOptimization: boolean;
    teaserDefaultHorizontalAlignment: string;
    teaserDefaultVerticalAlignment: string;
    teaserDefaultImageAlignment: string;
    teaserDefaultHorizontalTextAlignment: string;
    teaserDefaultTermsTextAlignment: string;
    maxRenderedTeasers: number;
}

@ClientConfig({ key: 'mvsExtendedMenuConfig', product: ClientConfigProductName.VIRTUALSPORTS })
@Injectable({
    providedIn: 'root',
    deps: [ClientConfigService],
    useFactory: ExtendedTopMenuFactory,
})
export class ExtendedTopMenuClientConfig {
    extendTopMenu: any;
}

export function mvsNavigationFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(MvsNavigationClientConfig);
}

export function mvsPageFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(MvsPageClientConfig);
}

export function mvsGamesFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(MvsGamesClientConfig);
}

export function mvsFeaturesFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(MvsFeaturesClientConfig);
}

export function mvsMetaTagsFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(MvsMetaTagsClientConfig);
}

export function TeaserConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(TeasersClientConfig);
}
export function ExtendedTopMenuFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(ExtendedTopMenuClientConfig);
}
