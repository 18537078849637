import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

import { PageMatrixComponent } from '@frontend/vanilla/features/content';

import { SeoboxesService } from './seoboxes.service';

@Component({
    standalone: true,
    selector: 'vs-seoboxes',
    templateUrl: 'seoboxes.component.html',
    imports: [CommonModule, PageMatrixComponent],
})
export class SeoboxesComponent implements OnChanges {
    @Input() currentGame: string;
    id: any = 1;
    seoboxes: Array<any> = [];
    constructor(private SeoService: SeoboxesService) {}

    ngOnChanges() {
        this.SeoService.getSeoboxes(this.currentGame).subscribe(
            (response: any) => {
                this.seoboxes = Array.isArray(response) ? response : [response];
            },
            (error: any) => {
                // eslint-disable-next-line no-console
                console.log(error);
            },
        );
    }
}
