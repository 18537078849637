import { Injectable } from '@angular/core';

import {
    LoadingIndicatorService,
    Logger,
    MessageLifetime,
    MessageQueueService,
    MessageType,
    NavigationService,
    TrackingService,
} from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api/api.service';
import { MvsGamesClientConfig } from '../client-config/virtualsports-client-config.models';

@Injectable({
    providedIn: 'root',
})
export class GameService {
    loadingIndicator: any;
    gamelaunched: any = 0;
    showGameTitle: boolean;
    games: any = this.mvsgame.games;
    game: any = {};
    from: any = {};
    iframeUrl: any;
    private isVisible = new BehaviorSubject<boolean>(false);
    public gameData = new BehaviorSubject<any>(null);
    public activeRouteUrl = new BehaviorSubject<any>(null);
    overlayVisibilityStatus: Observable<boolean> = this.isVisible.asObservable();
    constructor(
        public api: ApiService,
        public mvsgame: MvsGamesClientConfig,
        public tracker: TrackingService,
        private loadingIndicatorService: LoadingIndicatorService,
        private messageQueue: MessageQueueService,
        private navigation: NavigationService,
        private logger: Logger,
    ) {}
    //public gameDataObject: Observable<any> = this.gameData.asObservable();

    //gameDataObject: Observable<any> = this.gameData.asObservable();
    setOverlayVisibility(isVisible: boolean) {
        this.isVisible.next(isVisible);
    }

    gameDataInfo(game: any) {
        this.gameData.next(game);
    }

    activeUrlInfo(url: any) {
        this.activeRouteUrl.next(url);
    }

    launchGame(game: any, from: any) {
        this.loadingIndicator = this.loadingIndicatorService.start();
        this.api.get('launcher/' + game.vendorId + '/' + game.gameName, {}, { showSpinner: true }).subscribe(
            (response: any) => {
                if (this.loadingIndicator.inProgress) {
                    this.loadingIndicator.done();
                }
                this.tracker.updateDataLayer({ pageName: 'lobby', c15: '?source=' + from + '&gameId=' + game.gameName });
                if (response.redirectUrl !== null && response.redirectUrl !== '') {
                    this.navigation.goTo(response.redirectUrl);
                }

                //after getting response
                if (this.loadingIndicator.inProgress) {
                    this.loadingIndicator.done();
                }

                if (response.data == null) {
                    return;
                }
                if (response.data.message !== null && response.data.message !== '') {
                    this.messageQueue.clear();
                    this.messageQueue.add(response.data.message, MessageType.Error, MessageLifetime.Single);
                }
                if (response.data.redirectUrl !== null && response.data.redirectUrl !== '') {
                    this.navigation.goTo(response.data.redirectUrl);
                }
            },
            (error: any) => {
                this.logger.error(error);
            },
        );
    }
}
