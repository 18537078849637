<div id="embedded-game-view">
    <nav id="sports-nav">
        <div class="main-items desk-main-items">
            <span *ngFor="let game of games" [innerHtml]="game.title" (click)="launchGameInEmbeddedframe(game)" class="navSpan"></span>
            <ul>
                <li>
                    <button class="header-btn btn btn-primary ml-2" mat-raised-button (click)="openDialog()">{{ depositButtonString }}</button>
                </li>
                <li>
                    <a (click)="close()">
                        <span class="theme-close-i"></span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>

    <div class="embed-iframe">
        <ng-container *ngIf="iframeUrl"> </ng-container>
    </div>
</div>
