import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { TeaserService } from '../shared/teaser.service';
import { TeasersClientConfig } from './../client-config/virtualsports-client-config.models';

@Component({
    standalone: true,
    selector: 'vs-opt-teaser-text-position',
    templateUrl: 'vs-opt-teaser-textPosition.component.html',
    styleUrls: [],
    imports: [CommonModule, TrustAsHtmlPipe],
})
export class VsOptTeaserTextPositionComponent implements OnChanges {
    @Input() teaserVerticalPosition: string;
    @Input() teaserTitle: string;
    @Input() teaserSubTitle: string;

    verticalPosition: string;
    globalTeaserSettings: any;
    teaserDefaultTextVerticalPosition: string;
    constructor(
        private optimizedTeaserClientConfig: TeasersClientConfig,
        public _teaserService: TeaserService, // private optimizedTeaserManager:OptimizedTeaserManager,
    ) {}
    ngOnChanges() {
        this.globalTeaserSettings = this._teaserService.getGlobalTeaserSettings();
        this.teaserDefaultTextVerticalPosition = this.globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition
            ? this.globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition
            : this.optimizedTeaserClientConfig.teaserDefaultVerticalAlignment;

        this.verticalPosition = this.teaserVerticalPosition !== 'Default' ? this.teaserVerticalPosition : this.teaserDefaultTextVerticalPosition;
    }
}
