import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, NativeAppService } from '@frontend/vanilla/core';
import { MessagePanelComponent } from '@frontend/vanilla/features/message-panel';

import { EmbeddedGameViewComponent } from '../embedded-game-view/embedded-game-view.component';
import { LobbyComponent } from '../lobby/lobby.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { GameService } from '../shared/game.service';
import { VsOptimizedTeasersComponent } from '../vs-optimized-teasers/vs-optimized-teasers.component';

@Component({
    standalone: true,
    selector: 'vs-home',
    templateUrl: 'home.component.html',
    imports: [CommonModule, NavigationComponent, LobbyComponent, EmbeddedGameViewComponent, VsOptimizedTeasersComponent, MessagePanelComponent],
})
export class HomeComponent implements OnInit {
    isOverlay: boolean = false;
    isTouchDevice: boolean = true;
    headerTeasers: any = [];
    constructor(
        private deviceService: DeviceService,
        private nativeAppService: NativeAppService,
        public gameservice: GameService,
        public route: ActivatedRoute,
    ) {}
    ngOnInit(): void {
        this.subscribeToDataObservables();
        if (
            this.route.snapshot.queryParamMap.get('forcedEmbeddedLaunch') &&
            this.route.snapshot.queryParamMap.get('forcedEmbeddedLaunch')!.toLowerCase() === 'true'
        ) {
            this.isOverlay = true;
            this.checkForTouchDevice();
        }
    }

    subscribeToDataObservables() {
        this.gameservice.overlayVisibilityStatus.subscribe((isvisible: any) => {
            this.isOverlay = isvisible;
        });
    }

    checkForTouchDevice() {
        try {
            this.isTouchDevice =
                !this.nativeAppService.isDownloadClient &&
                this.deviceService.isTouch &&
                !window.matchMedia('(pointer: fine)').matches &&
                !navigator.userAgent.match(/Win64/ || /WOW64/);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log('Exception in setting isTouch Property: ' + error);
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }

    onResize() {}
}
