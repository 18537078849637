import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { DeviceService, DslPipe, NativeAppService, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { Subscription } from 'rxjs';

import { MvsPageClientConfig } from '../client-config/virtualsports-client-config.models';
import { GameService } from '../shared/game.service';
import { LoginDialogWrapperService } from '../shared/login-dialog-wrapper.service';
import { TeaserService } from '../shared/teaser.service';
import { OptTeaserItemComponent } from './vs-opt-teaser-item.component';

@Component({
    standalone: true,
    selector: 'vs-optimized-teasers',
    templateUrl: 'vs-optimized-teasers.component.html',
    styleUrls: ['vs-optimized-teasers.component.scss'],
    imports: [CommonModule, DslPipe, SwiperComponent, OptTeaserItemComponent],
})
export class VsOptimizedTeasersComponent implements OnInit, OnChanges, OnDestroy {
    @Input() fromNative: any;
    @Input() isDefaultCategory: boolean;
    @Input() identifierClass: string;
    @Input() teaserCategory: string;
    @Input() teaserLocation: string;
    @Input() currentGame: any;
    teasers: any;
    filteredteasers: any;

    globalTeaserSettings: any;
    interval: number = 5000;
    loop: boolean = true;
    arrows: boolean = false;
    dots: boolean = false;
    teaserDefaultHorizontalAlignment: any;
    teaserDefaultVerticalAlignment: any;
    teaserDefaultImageAlignment: any;
    isNewSportsNative: any;
    isLandNative: any;
    eventClickId: any;
    isTouch: boolean;
    subCategoryTeaserMode: any;
    Modeset: any;
    TeaserMode: any;
    categoryNavTeaserMode: any;
    teaserLaunchRealtiveUri: string = '/launchng/';
    responce: any;
    teaserOBS: Subscription;

    constructor(
        private navigation: NavigationService,
        private tracking: TrackingService,
        private casinoManager: DeviceService,
        private NativeApplication: NativeAppService,
        //  private mcPage: McPageClientConfig,
        // private gameDataManager: GameDataManager,
        // private gameConfig: GameConfigClientConfig,
        private user: UserService,
        private loginDialogService: LoginDialogWrapperService,
        //  private gameLaunchService: GameLaunchService,
        private gameService: GameService,
        // private gameLaunchPlaces: GameLaunchPlaces,
        // private nativeAppHelper: NativeAppHelperService,
        private Global: MvsPageClientConfig,
        public _teaserService: TeaserService, // private categoryManager: CategoryManager, // private casinoIFrameOverlayService: CasinoIFrameOverlayService,
    ) {}

    ngOnInit() {
        this.fromNative = this.fromNative === 'true' || this.fromNative === true ? true : false;
        this.isNewSportsNative = this.NativeApplication.isNative;
        this.isTouch = this.casinoManager.isTouch;

        this.subCategoryTeaserMode = this.Global.messages.subCategoryTeaserMode;
        this.TeaserMode = this.Global.messages.TeaserMode;
        this.categoryNavTeaserMode = this.Global.messages.categoryNavTeaserMode;
    }

    public teaserClick(event: any, teaser: any) {
        event.stopPropagation();

        if (this.fromNative || this.isNewSportsNative) {
            let link = undefined;
            if (teaser.nativeAppLink) {
                link = teaser.nativeAppLink.url;
            }
            if (link !== undefined) {
                this.track('tp', link, true);

                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    // if (this.vcClientService.isPokerVC() && this.isGameUrl(link)) {
                    //     this.launchVCGame(link);
                    //     return;
                    // }
                    this.navigation.goTo(link, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
                } else {
                    // let event: NativeEvent = {
                    //     eventName: this.eventClickId,
                    //     parameters: link.substr(infiIndex)
                    // };
                    this.eventClickId = this.NativeApplication.sendToNative(event);
                }
            }
        } else {
            if (teaser.gameLink) {
                this.track('t', teaser.gameLink, true);
            } else if (teaser.teaserLink.url) {
                this.track('tp', teaser.teaserLink.url, true);
                this.handleTeaserClick(teaser.teaserLink.url);
            }
        }
    }

    public track(where: any, what: any, isUrl: any) {
        this.tracking.updateDataLayer({
            'page.name': 'mcTeasers',
            'event': 'pageView',
            'page.url': '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }

    // public trackGameLaunch(gameLaunchInfo: any, isUrl: boolean, bannerPos: any) {
    //     let gameId: any, gameType = "";
    //     if (isUrl) {
    //         let launchUrlIndex: number = gameLaunchInfo.indexOf(this.teaserLaunchRealtiveUri);
    //         if (launchUrlIndex > 0) {
    //             gameId = gameLaunchInfo.substr(launchUrlIndex + this.teaserLaunchRealtiveUri.length);
    //             this.gameDataManager.getGameData(gameId).then((gameData: any) => {
    //                 if (!gameData.isDataNotAvailable) {
    //                     gameType = (gameData == null) ? "" : gameData.launchType;
    //                     let gameDisplayName = (gameData == null) ? '' : gameData.title;
    //                     this.tracking.triggerEvent('gameOpen',
    //                         {
    //                             'component.Game.position': bannerPos,
    //                             'component.Game.section': "Teaser",
    //                             'component.gameName': gameId,
    //                             'component.gameDisplayName': gameDisplayName,
    //                             'component.gameType': gameType
    //                         });
    //                 }
    //             });
    //         }
    //     }
    //     else {
    //         this.tracking.triggerEvent('gameOpen', {
    //             'component.Game.position': 1,
    //             'component.Game.section': "",
    //             'component.gameName': gameLaunchInfo,
    //             'component.gameType': gameType
    //         });
    //     }

    // }

    isGameUrl(link: string) {
        return link.indexOf(this.teaserLaunchRealtiveUri) > 0;
    }

    // launchVCGame(link: string) {
    //     if (this.isGameUrl(link)) {
    //         var gameId = this.getGameIdFromTeaserHref(link);
    //       //  this.vcClientService.handleVCGameLaunch(gameId, false);
    //     }
    // }
    handleTeaserClick(href: string) {
        const options: any = { skipLoadingIndicator: false };
        if (href.slice(-4).toLowerCase() === '.apk') {
            options.skipLoadingIndicator = true;
        }
        // if (this.gameConfig.nativeGameLaunchEnabled && this.isGameUrl(href)) {
        //     this.launchNativeGame(href);
        //     return;
        // }
        if (this.isTouch) {
            if (this.isGameUrl(href) && !this.user.isAuthenticated) {
                this.loginDialogService.open(href);
            } else {
                this.navigation.goTo(href, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
            }
        } else {
            if (this.isGameUrl(href) && !this.user.isAuthenticated) {
                // let playUrl: string = this.gameLaunchService.getPlayUrl({ gameId: this.getGameIdFromTeaserHref(href) }, false, this.gameLaunchPlaces.teaser, 'Teaser', 1, false);
                // this.loginDialogService.open(playUrl);
            } else if (this.isGameUrl(href)) {
                this.gameService.gameDataInfo({ gameId: this.getGameIdFromTeaserHref(href) });
                this.gameService.setOverlayVisibility(true);
            } else {
                this.navigation.goTo(href, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
            }
        }
    }

    getGameIdFromTeaserHref(link: string) {
        if (this.isGameUrl(link)) return link.substring(link.indexOf(this.teaserLaunchRealtiveUri) + this.teaserLaunchRealtiveUri.length);
        else return undefined;
    }

    getTeasers() {
        if (!this.teaserOBS) {
            this._teaserService.teaserDataObservable.subscribe((res: any) => {
                if (res) {
                    this.teasers = res.get(this.currentGame);
                    if (this.teasers) {
                        this.arrows = true;
                        this.dots = true;
                    } else {
                        this.arrows = false;
                        this.dots = false;
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.teaserOBS) {
            this.teaserOBS.unsubscribe();
        }
    }

    ngOnChanges() {
        this.getTeasers();
    }
}
