import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, STORE_PREFIX, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { appRoutes } from './app.routes';
import { provideVsBootstrap } from './bootstrap/vs-bootstrap.feature';

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

performance.mark('app-boot-start');
bootloader().then(() => {
    performance.mark('app-client-config-ready');
    performance.measure('client-config', 'app-boot-start', 'app-client-config-ready');
    bootstrapApplication(AppComponent, {
        // To guarantee correct execution of bootstrappers please add provideVanillaCore() before your providers
        providers: [
            provideRouter(appRoutes),
            provideAnimations(),
            provideVanillaCore(),
            provideLoaders(),
            { provide: STORE_PREFIX, useValue: 'virtualsports.' },
            provideVsBootstrap(),
        ],
    })
        .then(() => {
            performance.mark('app-boot-end');
            performance.measure('app-boot', 'app-boot-start', 'app-boot-end');
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
});
