import { CommonModule, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { LoadingIndicatorService, MessageQueueService, NavigationService } from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';

import { CashierIframeComponent } from '../cashier-iframe/cashier-iframe.component';
import { GameService } from '../shared/game.service';
import { MvsGamesClientConfig, MvsPageClientConfig } from './../client-config/virtualsports-client-config.models';

@Component({
    standalone: true,
    selector: 'vs-embedded-game-view',
    templateUrl: 'embedded-game-view.component.html',
    imports: [CommonModule],
})
export class EmbeddedGameViewComponent implements OnInit, OnDestroy {
    extrasColumnFlag: boolean = false;
    iframeUrl: any;
    depositButtonString: string = 'Deposit';
    innerWidth: any;
    loadingIndicator: any;
    iconShrinkClickedFirst: boolean = false;
    gameDataObservalble: any;
    games: any = this.mvsgame.games;
    constructor(
        private messageQueue: MessageQueueService,
        private balanceService: BalancePropertiesService,
        private route: ActivatedRoute,
        private gameService: GameService,
        private location: Location,
        private navigationService: NavigationService,
        private mvsPage: MvsPageClientConfig,
        private loadingIndicatorService: LoadingIndicatorService,
        private dialog: MatDialog,
        public mvsgame: MvsGamesClientConfig,
    ) {}

    ngOnInit(): void {
        const vendorId: string = this.route.snapshot.paramMap.get('vendorId') ?? '';
        if (vendorId) {
            document.body.classList.add('embed-hide-scroll');
        }
        this.messageQueue.clear({ clearPersistent: true });
        this.innerWidth = window.screen.width;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(CashierIframeComponent, {
            width: '790px',
            data: { name: 'cashier' },
        });

        dialogRef.afterClosed().subscribe(() => {
            // eslint-disable-next-line no-console
            console.log('The dialog was closed');
        });
    }

    close() {
        this.messageQueue.clear({ clearPersistent: true });
        this.balanceService.refresh();

        const iframe = document.getElementById('embedGameIframe') as HTMLIFrameElement;
        if (iframe && iframe.contentWindow) {
            const targetOrigin = new URL(iframe.src).origin;
            iframe.contentWindow.postMessage('PREVIOUS_CASINO_GAME_CLOSED', targetOrigin);
        }
        setTimeout(() => {
            document.body.classList.remove('embed-hide-scroll');
            this.gameService.setOverlayVisibility(false);
            this.innerWidth = window.screen.width;
            if (this.location.path().indexOf('forcedEmbeddedLaunch') > -1) {
                this.navigationService.goTo(this.mvsPage.prefix);
            }
        }, 500);
    }
    launchGameInEmbeddedframe(game: any) {
        this.loadingIndicator = this.loadingIndicatorService.start();
        // eslint-disable-next-line no-console
        console.log(game);
    }

    ForcelaunchGameInEmbeddedframe() {}

    onResize() {}

    ngOnDestroy() {
        this.gameDataObservalble.unsubscribe();
    }
}
