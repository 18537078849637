import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GameService } from './shared/game.service';

@Component({
    selector: 'vs-play-redirect',
    templateUrl: 'play-redirect.component.html',
})
export class PlayRedirectComponent implements OnInit {
    commonMessages: any;

    constructor(
        private gameservice: GameService,
        private route: ActivatedRoute,
    ) {}
    ngOnInit() {
        const gameid: any = this.route.snapshot.paramMap.get('gameId');
        const vendorid: any = this.route.snapshot.paramMap.get('vendorId');
        const game: any = {
            gameName: gameid,
            vendorId: vendorid,
        };
        this.launchGame(game);
    }

    launchGame(game: any) {
        this.gameservice.launchGame(game, '');
    }
}
