import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCommonModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { Page, UserService } from '@frontend/vanilla/core';
import { TrustAsResourceUrlPipe } from '@frontend/vanilla/shared/browser';

import { MvsPageClientConfig } from './../client-config/virtualsports-client-config.models';

@Component({
    standalone: true,
    selector: 'vs-cashier-iframe',
    templateUrl: 'cashier-iframe.component.html',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCommonModule,
        MatDialogModule,
        MatButtonModule,
        CdkTableModule,
        TrustAsResourceUrlPipe,
    ],
})
export class CashierIframeComponent implements OnInit {
    cashierIframeUrl: string;

    constructor(
        public dialogRef: MatDialogRef<CashierIframeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private mvsPage: MvsPageClientConfig,
        private user: UserService,
        private page: Page,
    ) {}
    onNoClick(): void {
        this.dialogRef.close();
    }
    ngOnInit() {
        this.cashierIframeUrl = this.mvsPage.cashierDepositUrlTemplate;
        this.cashierIframeUrl = this.cashierIframeUrl.replace('{0}', this.user.ssoToken ?? '');
        this.cashierIframeUrl = this.cashierIframeUrl.replace('{1}', this.page.lang);
        this.cashierIframeUrl = this.cashierIframeUrl.replace('{2}', '');
        this.cashierIframeUrl = this.cashierIframeUrl.replace('{3}', '');
        this.cashierIframeUrl = this.cashierIframeUrl + '&hideCloseButton=true';
    }
}
