<!--component html goes here -->
<div class="casino-opt-teasers-crousale" *ngIf="teasers && teasers.length > 0">
    <div class="opt-teasers">
        <vn-swiper
            *ngIf="teasers"
            [swiperOptions]="{
                loop: loop,
                autoHeight: true,
                allowTouchMove: false,
                autoplay: { delay: 5000, disableOnInteraction: false },
                pagination: { clickable: dots },
                navigation: arrows
            }">
            <ng-template *ngFor="let teaser of teasers | dsl | async; let i = index">
                <vs-opt-teaser-item [teaser]="teaser" [teaserIndex]="i" [teaserLocation]="teaserLocation"></vs-opt-teaser-item>
            </ng-template>
        </vn-swiper>
    </div>
</div>
