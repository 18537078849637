<!-- <h1 mat-dialog-title>Hi {{data.name}}</h1> -->
<div mat-dialog-content class="cashier-iframe-content embed-overlay-popup">
    <div class="cashier-iframe">
        <div mat-dialog-actions class="iframe-close theme-bg-popup">
            <button mat-button (click)="onNoClick()">
                <span class="theme-ex"></span>
            </button>
        </div>
        <iframe sandbox="allow-scripts allow-same-origin" [src]="cashierIframeUrl | trustAsResourceUrl" title=""> </iframe>
    </div>
</div>
