import { Injectable } from '@angular/core';

import { LoginDialogService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class LoginDialogWrapperService {
    constructor(private loginDialogService: LoginDialogService) {}
    open(returnUrl?: any) {
        if (returnUrl) {
            this.loginDialogService.whenReady.subscribe(() => {
                this.loginDialogService.open({ returnUrl: returnUrl });
            });
        } else {
            this.loginDialogService.whenReady.subscribe(() => {
                this.loginDialogService.open();
            });
        }
    }
}
