<div class="item-slide" [ngClass]="customClass" id="{{ customId }}" (click)="ctaTeaserClick($event, teaser)">
    <div class="opt-teaser-body">
        @if (teaser.backgroundImage) {
            <div class="opt-teaser-bg-image" [ngStyle]="{ 'background-image': 'url(' + teaser.backgroundImage.src + ')' }"></div>
        }
        <div [ngClass]="'opt-teaser-fg-img-' + imagePosition | lowercase" *ngIf="teaser.foregroundImage">
            <img [src]="teaser.foregroundImage.src" alt="Description of the image" />
        </div>
        <div [ngClass]="'opt-teaser-text-' + horizontalPosition | lowercase">
            <div class="opt-teaser-info" [ngClass]="'text-align-' + horizontalAlignment | lowercase">
                <vs-opt-teaser-text-position
                    [teaserVerticalPosition]="teaser.textVerticalPosition"
                    [teaserTitle]="teaser.teaserTitle"
                    [teaserSubTitle]="teaser.subTitle">
                </vs-opt-teaser-text-position>
                @if (teaser.cta) {
                    <div class="opt-teaser-cta">
                        <a
                            [href]="teaser.cta.url"
                            class="btn btn-primary"
                            (click)="ctaClick($event, teaser)"
                            [innerHtml]="teaser.cta.text | trustAsHtml"></a>
                    </div>
                }
            </div>
        </div>
        @if (teaser.significantTermsandConditions) {
            <div
                *ngIf=""
                class="small-terms"
                [ngClass]="'text-align-' + termsTextPosition | lowercase"
                [innerHtml]="teaser.significantTermsandConditions | trustAsHtml"
                (click)="termsClick($event, teaser)"></div>
        }
    </div>
</div>
