import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';

@Injectable({
    providedIn: 'root',
})
export class SeoboxesService {
    constructor(private api: ApiService) {}
    getSeoboxes(id: any): Observable<any> {
        return this.api.get('seoboxes', { categoryType: id });
    }
}
