import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';

import { NativeAppService, NativeEvent, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';

import { GameService } from '../shared/game.service';
import { LoginDialogWrapperService } from '../shared/login-dialog-wrapper.service';
import { TeaserService } from '../shared/teaser.service';
import { TeasersClientConfig } from './../client-config/virtualsports-client-config.models';
import { VsOptTeaserTextPositionComponent } from './vs-opt-teaser-textPosition.component';

@Component({
    standalone: true,
    selector: 'vs-opt-teaser-item',
    templateUrl: 'vs-opt-teaser-item.component.html',
    styleUrls: [],
    imports: [CommonModule, TrustAsHtmlPipe, VsOptTeaserTextPositionComponent],
})
export class OptTeaserItemComponent implements OnChanges, AfterViewInit {
    @Input() teaser: any;
    @Input() teaserIndex: number;
    @Input() teaserCategory: string;
    @Input() teaserLocation: string;
    @Input() fromNative: any;

    teaserDefaultTextHorizontalPosition: string;
    horizontalPosition: string;
    imagePosition: string;
    verticalPosition: string;
    horizontalAlignment: string;
    teaserDefaultTextVerticalPosition: string;
    teaserDefaultImagePosition: string;
    teaserDefaultHorizontalTextAlignment: string;
    termsTextPosition: string;
    teaserDefaultTermsTextAlignment: string;
    globalTeaserSettings: any;
    css: any;
    customCss: string;
    isNewSportsNative: any;
    isLandNative: any;
    eventClickId: any;
    isTouch: boolean;
    customId: any;
    customClass: string;
    teaserLaunchRealtiveUri: string = '/launchng/';

    constructor(
        private optimizedTeaserClientConfig: TeasersClientConfig,
        private navigation: NavigationService,
        private tracking: TrackingService,
        // private casinoManager: CasinoManager,
        private NativeApplication: NativeAppService,
        // private vcClientService: VCClientService,
        // private mcPage: McPageClientConfig,
        // private gameDataManager: GameDataManager,
        // private gameConfig: GameConfigClientConfig,
        private user: UserService,
        private loginDialogService: LoginDialogWrapperService,
        // private gameLaunchService: GameLaunchService,
        private gameService: GameService,
        public _teaserService: TeaserService, // private gameLaunchPlaces: GameLaunchPlaces,
    ) {
        // private nativeAppHelper: NativeAppHelperService,
        // private optimizedTeaserManager:OptimizedTeaserManager,
    }
    ngAfterViewInit() {
        const css = this.teaser.customCss;
        const head = document.getElementById(this.customId);
        const style1 = document.createElement('style');
        if (head != null) {
            head.appendChild(style1);
            style1.appendChild(document.createTextNode(css));
            style1.appendChild(document.createTextNode(css));
        }
    }
    ngOnChanges() {
        this.globalTeaserSettings = this._teaserService.getGlobalTeaserSettings();
        if (this.globalTeaserSettings.sharedList) {
            this.teaserDefaultTextHorizontalPosition = this.globalTeaserSettings.sharedList.teaserDefaultTextHorizontalPosition
                ? this.globalTeaserSettings.sharedList.teaserDefaultTextHorizontalPosition
                : this.optimizedTeaserClientConfig.teaserDefaultHorizontalAlignment;

            this.teaserDefaultTextVerticalPosition = this.globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition
                ? this.globalTeaserSettings.sharedList.teaserDefaultTextVerticalPosition
                : this.optimizedTeaserClientConfig.teaserDefaultVerticalAlignment;

            this.teaserDefaultImagePosition = this.globalTeaserSettings.sharedList.teaserDefaultImagePosition
                ? this.globalTeaserSettings.sharedList.teaserDefaultImagePosition
                : this.optimizedTeaserClientConfig.teaserDefaultImageAlignment;

            this.teaserDefaultTermsTextAlignment = this.globalTeaserSettings.sharedList.teaserDefaultTermsTextPosition
                ? this.globalTeaserSettings.sharedList.teaserDefaultTermsTextPosition
                : this.optimizedTeaserClientConfig.teaserDefaultTermsTextAlignment;

            this.teaserDefaultHorizontalTextAlignment = this.globalTeaserSettings.sharedList.teaserDefaultHorizontalTextAlignment
                ? this.globalTeaserSettings.sharedList.teaserDefaultHorizontalTextAlignment
                : this.optimizedTeaserClientConfig.teaserDefaultHorizontalTextAlignment;
        }
        this.horizontalPosition =
            this.teaser.textHorizontalPosition != 'Default' ? this.teaser.textHorizontalPosition : this.teaserDefaultTextHorizontalPosition;

        this.verticalPosition =
            this.teaser.textVerticalPosition != 'Default' ? this.teaser.textVerticalPosition : this.teaserDefaultTextVerticalPosition;

        this.imagePosition = this.teaser.imagePosition != 'Default' ? this.teaser.imagePosition : this.teaserDefaultImagePosition;

        this.termsTextPosition = this.teaserDefaultTermsTextAlignment;

        this.horizontalAlignment = this.teaserDefaultHorizontalTextAlignment;

        if (this.teaser.htmlClasses != undefined) {
            this.customClass = this.teaser.htmlClasses;
        } else if (this.isNullOrEmpty(this.teaser.htmlClasses)) {
            this.customClass = this.teaser.metadata.id.itemName;
        }
        if (this.teaser.htmlId != undefined) {
            this.customId = this.teaser.htmlId;
        } else if (this.isNullOrEmpty(this.teaser.htmlId)) {
            this.customId = this.teaser.metadata.id.itemName;
        }
    }

    isNullOrEmpty(val: string): boolean {
        if (!val || val === '') {
            return true;
        }
        return false;
    }
    public ctaClick(event: any, teaser: any) {
        event.stopPropagation();

        if (this.fromNative || this.isNewSportsNative) {
            let link = undefined;
            if (teaser.cta) {
                link = teaser.cta.url;
            }
            if (link !== undefined) {
                this.track('tp', link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.navigation.goTo(link, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
                } else {
                    const event: NativeEvent = {
                        eventName: this.eventClickId,
                        parameters: link.substr(infiIndex),
                    };
                    this.eventClickId = this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser.cta) {
            this.handleTeaserClick(teaser.cta.url);
        }
    }

    public ctaTeaserClick(event: any, teaser: any) {
        if (teaser.cta == null || teaser.cta == undefined) {
            if (teaser.teaserLink.url) {
                this.handleTeaserClick(teaser.teaserLink.url);
            }
            event.preventDefault();
        } else {
            event.stopImmediatePropagation();
        }
    }
    public termsClick(event: any, teaser: any) {
        event.stopPropagation();
        if (this.fromNative || this.isNewSportsNative) {
            let link = undefined;
            if (teaser.termsNativeLink.url) {
                link = teaser.termsNativeLink.url;
            }
            if (link !== undefined) {
                this.track('tp', link, true);
                const options = { skipLoadingIndicator: false };
                if (link.substr(link.length - 4, 4).toLowerCase() === '.apk') {
                    options.skipLoadingIndicator = true;
                }
                const infiIndex = link.indexOf('?event=promo');
                if (infiIndex === -1) {
                    this.navigation.goTo(link, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
                } else {
                    const event: NativeEvent = {
                        eventName: this.eventClickId,
                        parameters: link.substr(infiIndex),
                    };
                    this.eventClickId = this.NativeApplication.sendToNative(event);
                }
            }
        } else if (teaser.termsNativeLink) {
            this.track('tp', teaser.termsNativeLink.url, true);
            const options = { skipLoadingIndicator: false };
            if (teaser.termsNativeLink.url.substr(teaser.termsNativeLink.url.length - 4, 4).toLowerCase() === '.apk') {
                options.skipLoadingIndicator = true;
            }
            this.navigation.goTo(teaser.termsNativeLink.url, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
        }
    }
    public track(where: any, what: any, isUrl: any) {
        this.tracking.updateDataLayer({
            'page.name': 'mcTeasers',
            'event': 'pageView',
            'page.url': '?source=' + where + '&' + (isUrl ? 'href=' : 'gameId=') + what,
        });
    }

    isGameUrl(link: string) {
        return link.indexOf(this.teaserLaunchRealtiveUri) > 0;
    }

    getGameIdFromTeaserHref(link: string) {
        if (this.isGameUrl(link)) return link.substring(link.indexOf(this.teaserLaunchRealtiveUri) + this.teaserLaunchRealtiveUri.length);
        else return undefined;
    }

    handleTeaserClick(href: string) {
        const options: any = { skipLoadingIndicator: false };
        if (href.substring(href.length - 4, 4).toLowerCase() === '.apk') {
            options.skipLoadingIndicator = true;

            if (this.isTouch) {
                if (this.isGameUrl(href) && !this.user.isAuthenticated) {
                    this.loginDialogService.open(href);
                } else {
                    this.navigation.goTo(href, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
                }
            } else {
                if (this.isGameUrl(href) && this.user.isAuthenticated) {
                    this.gameService.gameDataInfo({ gameId: this.getGameIdFromTeaserHref(href) });
                    this.gameService.setOverlayVisibility(true);
                } else {
                    this.navigation.goTo(href, { showSpinnerForExternalNavigation: !options.skipLoadingIndicator });
                }
            }
        }
    }
}
