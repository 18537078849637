import { Injectable } from '@angular/core';

import { UserService } from '@frontend/vanilla/core';

import { ApiService } from '../api/api.service';
import { MvsNavigationClientConfig } from '../client-config/virtualsports-client-config.models';

@Injectable({
    providedIn: 'root',
})
export class BetSearchService {
    defaultConfig: any = {
        url: this.mvsNavigation.connectionConfig.urlBase,
        timeout: this.mvsNavigation.connectionConfig.requestTimeoutBetSearch || 20000,
    };
    constructor(
        private mvsNavigation: MvsNavigationClientConfig,
        public user: UserService,
        public api: ApiService,
    ) {}

    get: any = function () {
        const userCountry = (this.user.country || (!this.user.isAuthenticated ? this.user.geoCountry : '')).toLowerCase();
        let apiUrl: string = this.defaultConfig['url'];
        apiUrl = apiUrl.replace('{country}', this.user.claims.get('country'));
        apiUrl = apiUrl.replace('{u_country}', userCountry);
        return this.api.get(apiUrl);
    };
}
