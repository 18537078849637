<div>
    <div *ngIf="isTouch" (window:resize)="onResize()">
        <vs-optimized-teasers [currentGame]="currentGame.gameName"> </vs-optimized-teasers>
        <div class="vs-title" *ngIf="VStitle && windowWidth < 768">
            <h2 class="game-heading">
                <span class="currentgame-title">{{ VStitle }}</span>
            </h2>
        </div>
        <div class="unscrollable-games">
            <div *ngFor="let game of lobbygames" class="game {{ game.vendorId }}">
                <div *ngIf="game?.icon?.src">
                    <a (click)="launchGame(game, 'p')" class="game-image-link">
                        <img [src]="game.icon.src" alt="{{ game.icon.alt }}" width="{{ game.icon.width }}" height="{{ game.icon.height }}" />
                    </a>
                    <div *ngIf="showGameTitle" class="game-title">
                        <a (click)="launchGame(game, 'l')">{{ game.title }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isTouch" (window:resize)="onResize()">
        <vs-optimized-teasers [currentGame]="currentGame.gameName"> </vs-optimized-teasers>
        <div class="embed-iframe">
            <ng-container *ngIf="iframeUrl">
                <iframe
                    id="embedGameIframe"
                    class="iframeClass"
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allow="autoplay"
                    [src]="iframeUrl | trustAsResourceUrl"
                    title=""></iframe>
            </ng-container>
        </div>

        <div id="vsSeoboxDiv" *ngIf="displaySEOBox">
            <vs-seoboxes *ngIf="isTouch" [currentGame]="currentGame.gameName"></vs-seoboxes>
            <vs-seoboxes *ngIf="!isTouch && showSeo" [currentGame]="currentGame.gameName"></vs-seoboxes>
        </div>
    </div>
</div>
