import { Injectable } from '@angular/core';

import { OnAppInit } from '@frontend/vanilla/core';

import { MvsGamesClientConfig } from './../client-config/virtualsports-client-config.models';
import { TeaserService } from './../shared/teaser.service';

@Injectable()
export class VSBootstrapService implements OnAppInit {
    games: string[];
    constructor(private teaserService: TeaserService, private mvsgames: MvsGamesClientConfig) {}
    onAppInit() {
        const gameArray: any = [];
        this.games = this.mvsgames.games;
        this.games.forEach((game: any) => {
            gameArray.push(game.gameName);
        });

        //here need to send navitems for desktop if mobile create single and set to setteasers method

        this.teaserService.setTeasers(gameArray);
    }
}
