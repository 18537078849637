import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../api/api.service';
import { TeasersClientConfig } from '../client-config/virtualsports-client-config.models';

@Injectable({
    providedIn: 'root',
})
export class TeaserService {
    headerTeasers: any;
    globalTeaserSettings: any;
    private teaserDataPublisher = new BehaviorSubject<any>(null);
    teaserDataObservable: Observable<any> = this.teaserDataPublisher.asObservable();
    teaserMap: Map<string, any> = new Map<string, any>();

    constructor(
        public api: ApiService,
        private teasersClientConfig: TeasersClientConfig,
    ) {}
    setTeasers(navItems: string[]) {
        if (this.teasersClientConfig.teaserOptimization) {
            this.api.get('teasers/GetTeaserData', { navItems: navItems }).subscribe((response) => {
                if (response) {
                    this.globalTeaserSettings = response.globalTeaserSettings;
                    if (response.optimizedTeaserContents && response.optimizedTeaserContents.length > 0) {
                        for (const teaserItem of response.optimizedTeaserContents) {
                            const parentId = teaserItem.metadata.id.parent.itemName;
                            this.setTeaserMap(parentId, teaserItem);
                        }

                        this.teaserDataPublisher.next(this.teaserMap);
                    }
                }
            });
        }
    }

    setTeaserMap(parentId: string, teaserItem: any) {
        let teaserArr: any[];
        if (!this.teaserMap.get(parentId)) {
            teaserArr = [];
        } else {
            teaserArr = this.teaserMap.get(parentId);
        }
        teaserArr.push(teaserItem);
        this.teaserMap.set(parentId, teaserArr);
    }

    getTeasersForGameName(gameName: string) {
        return this.teaserMap.get(gameName);
    }

    getGlobalTeaserSettings() {
        return this.globalTeaserSettings;
    }
}
