import { Injectable } from '@angular/core';

import { LoadingIndicatorService, NavigationService, TrackingService, UserService } from '@frontend/vanilla/core';

import { MvsNavigationClientConfig, MvsPageClientConfig } from '../client-config/virtualsports-client-config.models';
import { BetSearchService } from './betsearch.service';

@Injectable({
    providedIn: 'root',
})
export class VSNavigationService {
    public navigation = {
        beforeSports: this.mvsNavigation.navigationBar.beforeSports,
        sports: new Array<any>(),
        afterSports: this.mvsNavigation.navigationBar.afterSports,
    };
    constructor(
        public mvsNavigation: MvsNavigationClientConfig,
        public vanillaNavSvc: NavigationService,
        public loadingIndicator: LoadingIndicatorService,
        public mvsPage: MvsPageClientConfig,
        public BetSearch: BetSearchService,
        public user: UserService,
        public tracker: TrackingService,
    ) {}

    performItemAction(menuitem: any) {
        const data = { pageName: 'M2_virtualsports_lobby', c15: '?source=top_nav' };
        this.tracker.updateDataLayer(data);

        let link = menuitem.link;
        if (link !== null && link !== undefined && link.indexOf('http') === -1) {
            link = this.mvsPage.sportsPage + '/sports' + link;
        }
        this.vanillaNavSvc.goTo(link);
    }

    removeFromNavigation(activeName: any) {
        this.navigation.beforeSports = this.navigation.beforeSports.filter(function (menuitem: any) {
            return menuitem.activeName !== activeName;
        });
        this.navigation.afterSports = this.navigation.afterSports.filter(function (menuitem: any) {
            return menuitem.activeName !== activeName;
        });
    }

    //  init () {
    //     getSportNavigation();
    // }
    getSportNavigation() {
        const loadingIndicator = this.loadingIndicator.start();

        const params = {
            counts: ['live'],
            overview: JSON.stringify([{ col_location_by_events: 0 }]),
        };

        this.BetSearch.get('ms2common', params).subscribe(
            (result: any) => {
                try {
                    this.navigation.sports = result.slice(0, this.mvsNavigation.navigationBar.settings.SortedSportsDisplayLimit);

                    // if no live events, filter out 'live' menuitem
                    // if (!response.items.counts.live) {
                    //     this.removeFromNavigation("live");
                    // }
                } catch (ex) {
                    loadingIndicator.done();
                }
                loadingIndicator.done();
            },
            () => {
                loadingIndicator.done();
            },
        );
    }
}
