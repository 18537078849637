<div class="navigation-wrapper" (window:resize)="onResize()">
    <nav id="sports-nav" #sportsNav>
        <div class="main-items" *ngIf="isMobile">
            <ng-container *ngIf="extendTopMenuState && !extendedTopMenuToRight">
                <i class="{{ menu.cssClass }}" *ngFor="let menu of extendTopMenu" (click)="goToUrl(menu.url)">
                    <span [innerHtml]="menu.title"></span>
                </i>
            </ng-container>
            <i class="sports-all" *ngIf="enableSportsNav" (click)="goToEventsAll()"><span [innerHtml]="AZSports"></span></i>

            <i class="{{ link.icon }}" (click)="performAction(link)" *ngFor="let link of beforeSportslist">
                <span [innerHtml]="link.title"></span>
            </i>

            <i class="{{ 'sports-' + sport.tag.id }}" (click)="goToSport(sport.tag.id)" *ngFor="let sport of sportItemslist; trackBy: trackbysport">
                <span [innerHtml]="sport.tag.name.value"></span
            ></i>

            <i
                [ngClass]="{ active: link.activeName == 'virtualsports', special: link.isSpecial }"
                class="{{ link.icon }}"
                (click)="performAction(link)"
                *ngFor="let link of afterSportslist"
                ><span [innerHtml]="link.title"></span
            ></i>
            <ng-container *ngIf="extendTopMenuState && extendedTopMenuToRight">
                <i class="{{ menu.cssClass }}" *ngFor="let menu of extendTopMenu" (click)="goToUrl(menu.url)">
                    <span [innerHtml]="menu.title"></span>
                </i>
            </ng-container>
        </div>
        <!-- for desktop-->
        <div class="main-items desk-main-items" *ngIf="!isMobile">
            <span *ngFor="let game of games">
                <a *ngIf="extendTopMenuState && game.isTopMenu == 'true' && game?.pathname" [routerLink]="game.url">
                    <span
                        [innerHtml]="game.title"
                        [ngClass]="{
                            navSpan: true,
                            activeclass:
                                (game?.gameName == currentUrlSpecificString && !extendTopMenuState) ||
                                game?.gameName == ((firstGameName || currentUrlSpecificString) && extendTopMenuState)
                        }"></span>
                </a>
                <a *ngIf="extendTopMenuState && game.isTopMenu == 'true' && !game?.pathname" [href]="game.url">
                    <span
                        [innerHtml]="game.title"
                        [ngClass]="{
                            navSpan: true,
                            activeclass:
                                (game?.gameName == currentUrlSpecificString && !extendTopMenuState) ||
                                game?.gameName == ((firstGameName || currentUrlSpecificString) && extendTopMenuState)
                        }"></span>
                </a>
                <a *ngIf="game?.vendorId != 'live_sports' && !game.isTopMenu">
                    <span
                        [innerHtml]="game.title"
                        (click)="launchGameInIframe(game)"
                        [ngClass]="{
                            navSpan: true,
                            activeclass:
                                (game?.gameName == currentUrlSpecificString && !extendTopMenuState) ||
                                (game?.gameName == firstGameName && extendTopMenuState)
                        }"></span>
                </a>
                <a *ngIf="game?.vendorId == 'live_sports' && !game.isTopMenu" href="{{ sportsRelativeUrlTemplate }}/{{ game?.gameName }}">
                    <span [innerHtml]="game.title" [ngClass]="{ navSpan: true }"></span>
                </a>
            </span>

            <ul>
                <li
                    *ngIf="moreCategory?.length > 0"
                    id="moregameslist"
                    [ngClass]="{ activeclass: activelinkstatus }"
                    (mouseenter)="moreHover(false)"
                    (mouseleave)="moreHover(true)"
                    (click)="moreClick($event)">
                    <span>
                        {{ moreText }}
                    </span>
                    <ul id="more-select" [hidden]="hideMore" [style.opacity]="opacity">
                        <ng-container *ngFor="let category of moreCategory">
                            <li
                                *ngIf="category?.title"
                                (click)="launchGameInIframe(category)"
                                [ngClass]="{
                                    moreactiveclass: category?.gameName == (extendTopMenuState ? firstGameName : currentUrlSpecificString)
                                }">
                                <span [innerHtml]="category?.title" class="moreSpan"></span>
                            </li>
                        </ng-container>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
</div>
